import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography, Box, Grid } from '@mui/material';
import { useAllPrismicDocumentsByType } from '@prismicio/react';

const classes = {
  imageCorners: `image-imageCorners`,
};

const StyledBox = styled(Box)({
  [`& .${classes.imageCorners}`]: {
    borderRadius: '50px 15px 0px 0px',
  },
});

const Employees = () => {

  const ResponsiveImage = ({field}) => {
    return (
      <StyledBox>
        <picture>
          {field.mobile ? <source media="(max-width: 400px)" srcSet={field.mobile.url} /> : null}
          {field.tablet ? <source media="(max-width: 900px)" srcSet={field.tablet.url} /> : null}
          <source media="(max-width: 1600px)" srcSet={field.url} />
          <source srcSet={field.url} />
          <img src={field.url} alt={field.alt} width="100%" border="0" padding="0" margin="0" className={classes.imageCorners} />
        </picture>
      </StyledBox>
    )
  }

  const [content] = useAllPrismicDocumentsByType('employees');

  if (content) {
    return (
      <Container maxWidth="lg">
        <Grid container justifyContent="center">
          {content.map( (slice,i) => {
            return (
              <Grid item md={4} sm={6} xs={12} key={'Employee' + i} pl={4} pr={4} pb={6}>
                <Box sx={{boxShadow: '2px 2px 10px rgba(0,0,0,0.2)', borderRadius: '50px 15px', textAlign: 'center'}} mb={0} pb={0} pt={0} alignItems="flex-end">
                  <ResponsiveImage field={slice.data.image} />
                  <Typography variant="h6" color="primary" mt={1} sx={{fontWeight: 'bold'}}>{slice.data.name[0].text}</Typography>
                  <Typography variant="body1" color="textSecondary" pb={2}>{slice.data.title[0].text}</Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Container>
    );
  } else {
    return null;
  }
}

export default Employees;

