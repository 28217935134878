import React, {useState, useEffect} from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Container, Typography, Link, Box, IconButton, Fade } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import footerLogo from '../../images/nav/Meetify-logo-200.png';
import { format } from 'date-fns-tz';
// Prismic
import { client } from '../main/prismic'
import { PrismicProvider } from '@prismicio/react'
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { linkResolver } from '../main/slices/slices';

const Footer = (props) => {

  const [fadeTime, setFadeTime] = useState(false);
  let document;
  [document] = useAllPrismicDocumentsByType((window.location.pathname === '/' || window.location.pathname === '/features') ? 'article' : '');

  useEffect( ()=> {
    if (props.appRoot.fadeFooter !== fadeTime) {
      setFadeTime(Boolean(props.appRoot.fadeFooter));
    }
  }, [fadeTime, props.appRoot.fadeFooter])
    
  return (
      <Container>
        <Fade in={fadeTime} timeout={Number(process.env.REACT_APP_TRANS_SPEED_SLOW)}>
        <Box mt={4} py={6} textAlign="center">
          <Link component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}`} color="inherit" underline="none" aria-label='Meetify Logo'>
            <img src={footerLogo} alt="Meetify Logo" width="200" height="61" border="0" />
          </Link>
          <Box component="nav" mt={4} mb={1} sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            <Link component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/invite`} variant="body1" color="textPrimary"  sx={{ml: 3, mr: 3, mb: 1, fontWeight: 'bold'}}>Invite</Link>
            <Link component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/features`} variant="body1" color="textPrimary"  sx={{ml: 3, mr: 3, mb: 1, fontWeight: 'bold'}}>Features</Link>
            <Link component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/pricing`} variant="body1" color="textPrimary"  sx={{ml: 3, mr: 3, mb: 1, fontWeight: 'bold'}}>Pricing</Link>
            <Link component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/resources`} variant="body1" color="textPrimary"  sx={{ml: 3, mr: 3, mb: 1, fontWeight: 'bold'}}>Resources</Link>
            <Link component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/about`} variant="body1" color="textPrimary"  sx={{ml: 3, mr: 3, mb: 1, fontWeight: 'bold'}}>About</Link>
            { props.noLinks ? null : <Link component={ReactRouterLink} to="/support" variant="body1" color="textPrimary"  sx={{ml: 3, mr: 3, mb: 1, fontWeight: 'bold'}}>Support</Link> }
            <Link href={`${process.env.REACT_APP_WEB_URL}/sitemap.html`} variant="body1" color="textPrimary"  sx={{ml: 3, mr: 3, mb: 1, fontWeight: 'bold'}}>Sitemap</Link>
          </Box>
          {document && document.length ?
            <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}} mb={2}>
              {document.filter( (x,index) => index < 10).map( (article,index) => {
                return (
                  <Link key={'article'+index} component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/resources/${article.tags.length ? article.tags[0].toLowerCase() + '/' : ''}${article.uid}`} variant="body1" color="textPrimary"  sx={{ml: 3, mr: 3, mb: 1}}>{article.data.title[0].text}</Link>
                )
              })}
            </Box>
            : null}
          <Box mb={3}>
            <IconButton color="primary" aria-label="Facebook" href="https://www.facebook.com/Meetifycom/" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </IconButton>
            <IconButton color="primary" aria-label="Twitter" href="https://twitter.com/meetifycom" target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </IconButton>
            <IconButton color="primary" aria-label="Instagram" href="https://instagram.com/meetifycom" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </IconButton>
            <IconButton color="primary" aria-label="LinkedIn" href="https://www.linkedin.com/company/meetifycom/" target="_blank" rel="noopener noreferrer">
              <LinkedInIcon />
            </IconButton>
          </Box>
          <Typography color="textSecondary" component="p" variant="caption" gutterBottom={false}>© {format( new Date(), 'yyyy')} Meetify, Inc. All rights reserved.</Typography>
          <Link component={ReactRouterLink} to="/privacy-policy" variant="caption" color="textSecondary">Privacy Policy</Link>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link component={ReactRouterLink} to="/terms-of-service" variant="caption" color="textSecondary">Terms of Service</Link>
        </Box>
        </Fade>
      </Container>
  );
}

const Wrapper = (props) => {
  return (
    <PrismicProvider client={client} linkResolver={linkResolver}>
      <Footer {...props} />
    </PrismicProvider>
  )
}

export default Wrapper;

