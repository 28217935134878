import React, {useEffect, useState, useCallback} from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import { Container, Box, Snackbar, Fade, Alert, AlertTitle, Typography, Stack, Tabs, Tab, Select, MenuItem, TextField, Button } from '@mui/material';
import MetaTags from '../library/metaTags';
import diag from '../../images/pricing/blue-abstract.jpg';
// import diag from '../../images/resources/blue-grunge.jpg';
import { customFade, getCsrfToken } from '../../services/guiService';
import LoadingIcon from '../library/loadingIcon';
import PublicFooter from '../nav/footerPublic';
import ArticlesLarge from './slices/articlesLarge';
// cms
import { client } from './prismic'
import { PrismicProvider, PrismicToolbar } from '@prismicio/react'
import { linkResolver } from './slices/slices';
import axios from 'axios';

// Custom classes
const classes = {
  imageCorners: `image-imageCorners`,
  stickyRight: 'right-stickyRight',
};
// Classes for styling images and boxes
const StyledContainer= styled(Container)({
  [`& .${classes.imageCorners}`]: {
    borderRadius: '20px 20px 20px 20px',
  },
  [`& .${classes.stickyRight}`]: {
    textAlign: 'center',
    position: "sticky",
    top: "1rem",
  },
});

const Resources = (props) => {

  const navigate = useNavigate();
  const { category } = useParams();
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false, false, false]);
  const [loadingState, setLoadingState] = useState('loading');
  const [document, setDocument] = useState(); // articles
  const [document2, setDocument2] = useState(); // resources main page
  const [document3, setDocument3] = useState(); // resource category main pages
  const [filteredDocs, setFilteredDocs] = useState(); // filtered articles
  const [filteredPage, setFilteredPage] = useState(); // the page you are on
  const [categories, setCategories] = useState(); // categories for the tabs
  const [textField2, setTextField2] = useState('');
  const [snack, setSnack] = useState({display: false, title: '', message: '', type: 'success'});
  const [ref, setRef] = useState();
  const [tabVal, setTabVal] = useState(category ? category : 'all');

  // Fade and slide animations
  const fade = useCallback( (direction, redirect, includeSlide) => { customFade(null, fadeTime.length, setFadeTime, null, direction, redirect, null, includeSlide, props.appRoot.setFadeFooter) },[props.appRoot.setFadeFooter, fadeTime.length]);

  // Handle tab change
  const handleTabChange = (e, val) => {
    const newCategory = val && val.props ? val.props.value : val;
    let url = newCategory === 'all' ? '' : newCategory.toLowerCase();
    if (url) {
      setFilteredPage(document3.filter(x => x.uid === newCategory)[0]);
    } else {
      setFilteredPage(document2[0]);
    }
    if (category) {
      setTabVal(newCategory);
      navigate(`/resources${url ? '/' + url : ''}`);
    } else {
      setTabVal(newCategory);
      navigate(`${url}`);
    }
  }

  // Close snackbar notifications
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnack({...snack, display: false});
  };

  const subscribe = async (email) => {
    const localToken = await getCsrfToken();
    const dataPackage = {
      email: email,
      source: 'home page'
    }
    const postUrl = process.env.REACT_APP_API_URL + '/marketing/contact'
    axios.post(postUrl, dataPackage, {withCredentials: true, headers: {'CSRF-Token': localToken}})
    .then( (response) => {
      setSnack({display: true, title: 'Thank you!', message: `We've added you to our list and will keep you up to date on the latest Meetify news.`, type: 'success'});
      setTextField2('');
    })
    .catch( (err) => {
      setSnack({display: true, title: 'There was a problem.', message: 'The email you entered was invalid, Please revise.', type: 'error'});
    })
  }
  
  const filterDocs = useCallback( () => {
    if (document && document.length) {
      if (category) {
        setFilteredDocs(document.filter(x => x.tags[0].toLowerCase() === category));
        setFilteredPage(document3.filter(x => x.uid === category)[0]);
      } else {
        setFilteredDocs(document);
        setFilteredPage(document2[0]);
      }
    }
  }, [document, category]);

  // Initial load
  useEffect( () => {
    // Get the documents
    const getDocs = async () => {
      let localRef = ref;
      if (!localRef) {
        const response = await axios.get('https://meetify.cdn.prismic.io/api/v2');
        setRef(response.data.refs[0].ref);
        localRef = response.data.refs[0].ref;
      }
      const qVal = '[[at(document.type, "article")]]';
      const qVal2 = '[[at(document.type, "resources")]]';
      const qVal3 = '[[at(document.type, "resource_category")]]';
      const ordering = '[my.article.publish_date desc]';
      let urls = [
        `https://meetify.cdn.prismic.io/api/v2/documents/search?q=${encodeURI(qVal)}&pageSize=100&ref=${localRef}&orderings=${encodeURI(ordering)}`,
        `https://meetify.cdn.prismic.io/api/v2/documents/search?q=${encodeURI(qVal2)}&pageSize=100&ref=${localRef}&orderings=${encodeURI(ordering)}`,
        `https://meetify.cdn.prismic.io/api/v2/documents/search?q=${encodeURI(qVal3)}&pageSize=100&ref=${localRef}&orderings=${encodeURI(ordering)}`,
      ];
      const requests = urls.map((url) => axios.get(url));
      const responses = await axios.all(requests);
      if (!responses || responses.length !== 3 || !responses[0].data.results || !responses[1].data.results|| !responses[2].data.results) {
        navigate('/404');
      } else {
        setDocument(responses[0].data.results);
        setDocument2(responses[1].data.results);
        setDocument3(responses[2].data.results);
        // save categories
        let cats = responses[2].data.results.map(x => x.uid);
        cats = cats.sort( (a,b) => a > b ? 1 : -1);
        setCategories(cats);
        if (category) {
          setFilteredDocs(responses[0].data.results.filter(x => x.tags[0].toLowerCase() === category));
          setFilteredPage(responses[2].data.results.filter(x => x.uid === category)[0]);
        } else {
          setFilteredDocs(responses[0].data.results);
          setFilteredPage(responses[1].data.results[0]);
        }
        if (!fadeTime[0]) {
          setLoadingState('complete');
          fade('in');
        }
      }
    };
    if (loadingState === 'loading') {
      setLoadingState('processing')
      getDocs();
    }
  }, [loadingState, fade, category, ref]);

  // Get documents whenever category changes
  useEffect( () => {
    filterDocs()
  }, [category]);

  // preRenderReady
  useEffect( () => {
    const goRender = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      window.prerenderReady = true;
    }
    if (!window.prerenderReady && fadeTime[fadeTime.length-1] === true) goRender();
  },[fadeTime]);

  if (loadingState !== 'complete') {
    return (
      <LoadingIcon />
    )
  } else {
    return (
      <Box>
        <MetaTags content={filteredPage} />
        {/* Header */}
        <Box sx={{width: '100%', backgroundColor: '#112945', height: {xs: '350px', md: '350px', boxShadow: `0px 3px 12px 0px #c6e7e9`}}}>
            <Box sx={{width: '100%', height: '100%', backgroundImage: `url(${diag})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
              <Stack sx={{height: '100%'}} pl={2} pr={2} alignItems="center" justifyContent="center">
                  <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}><Typography variant="h3" component="h1" sx={{color: 'white', fontWeight: 'bold'}}>{filteredPage.data.body1[0].primary.title[0].text}{/* Resources, textShadow: `2px 2px 2px rgb(0,0,0,0.3)` */}</Typography></Fade>
                  <br />
                  <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}><Box pt={2} align="center">
                    <Typography variant="h6" component="h2" color="secondary" sx={{lineHeight: '2.0em', fontWeight: 'bold'}}>
                      {filteredPage.data.body1[0].primary.subheader[0].text}
                      {/* Tips on productivity, relationship building and growing a business */}
                    </Typography>
                  </Box>
                  </Fade>               
              </Stack>
            </Box>
        </Box>         
        <Box mb={6}>
          <StyledContainer>
            <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
              <Box pt={4} pb={1} sx={{justifyContent: 'center'}}>
                {/* Desktop & iPad */}
                <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                  <Tabs centered value={tabVal} onChange={handleTabChange} aria-label="Tabs for resource categories">
                    <Tab label="All" value="all" key="all" sx={{paddingRight: '16px', paddingLeft:'16px', paddingBottom: '8px', minWidth: '30px'}}></Tab>
                    {categories.map( (catLink, indx) => {
                      return (
                        <Tab label={catLink.toUpperCase()} value={catLink.toLowerCase()} key={`category${indx}`} sx={{paddingRight: '16px', paddingLeft:'16px', paddingBottom: '8px', minWidth: '30px'}}></Tab>
                      )
                    })}
                  </Tabs>
                </Box> 
                {/* Mobile */}
                <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                  <Select
                  size="small"
                    value={tabVal}
                    onChange={handleTabChange}
                    sx={{width: '100%', minWidth: '200px'}}
                  >
                    <MenuItem value="all" key="all" >ALL</MenuItem>
                    {categories.map( (catLink, indx) => {
                      return (
                        <MenuItem value={catLink.toLowerCase()} key={`category${indx}`} >{catLink.toUpperCase()}</MenuItem>
                      )
                    })}
                  </Select>
                </Box>
              </Box>
            </Fade>
            <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
              <Box mt={4} pl={{xs: 3, xl: 0}} pr={{xs: 2, lg: 0}}>
                <ArticlesLarge document={filteredDocs} category={category} />
              </Box>
            </Fade>


          </StyledContainer>
        </Box>
        {/* Newsletter sign up */}
        {/* <Fade in={fadeTime[5]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
        <Box mt={4} mb={8} pt={6} pb={6} sx={{width: '100%', backgroundColor: '#edf5f6', boxShadow: `inset 0px 0px 100px 0px #c6e7e9`}}>
          <Box align="center">
            <Box>
              <Typography variant="h4" sx={{fontWeight: 'bold'}}>Sign up to be the first to know</Typography>
            </Box>
            <Box mt={2} sx={{width: {sm: '100%', md: '80%', lg: '70%'}}}>
              <Typography variant="body1" color="primary" >about new Meetify features, productivity tips, relationship building and more.</Typography>
            </Box>
            <Stack direction="row" mt={2} spacing={2} justifyContent="center">
              <TextField id="contactInfo" label="Email" variant="outlined" required value={textField2} onChange={ (e) => setTextField2(e.target.value) } size="small" sx={{backgroundColor: 'rgb(255,255,255,1)', width: 200}} />
              <Button type="button" variant="contained" color="primary" onClick={ () => subscribe(textField2) }> Sign Up</Button>
            </Stack>
          </Box>
        </Box>
        </Fade> */}

        <PublicFooter appRoot={props.appRoot} />

        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={snack.display} autoHideDuration={4500} onClose={handleSnackClose}>
          <Alert severity={snack.type} elevation={6} variant="filled" onClose={handleSnackClose}>
            <AlertTitle sx={{color: 'black', fontWeight: 'bold'}}>{snack.title}</AlertTitle>
            {snack.message}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

const Wrapper = (props) => {
  return (
    <PrismicProvider client={client} linkResolver={linkResolver}>
      <Resources {...props} />
      {process.env.REACT_APP_ENV !== 'production' ? <PrismicToolbar repositoryName="meetify" /> : null}
    </PrismicProvider>
  )
}

export default Wrapper;