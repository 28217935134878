import React, {useEffect} from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Link, Toolbar, AppBar, Box, IconButton, Menu, MenuItem, Button, Stack, Tooltip, List, Collapse, ListItem, Drawer, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { Menu as MenuIcon, ArrowDropDown, AddCircleOutline, Forum, Close, CalendarMonth, AccessTime, Settings, Person, Logout, ExpandLess, ExpandMore }  from '@mui/icons-material';
import logo from '../../images/nav/Meetify-logo-200.png';

const PREFIX = 'Header';

const classes = {
  label: `${PREFIX}-label`,
};

const StyledAppBar = styled(AppBar)({
  [`& .${classes.label}`]: {
    marginLeft: '0px',
  },
});

const ListItemIconCustom = styled(ListItemIcon)({
  minWidth: '36px'
});

const ListItemTextCustom = styled(ListItemText)({
  paddingLeft: '38px',
  marginTop: '0px',
  marginBottom: '0px',
  fontSize: '0.875em'
});

const ListItemCustom = styled(ListItem)({
  backgroundColor: 'rgb(0,0,0,0.05)',
  borderBottom: '1px solid rgba(0,0,0,0.1)'
});


const Header = (props) => {

  const appRoot = props.appRoot ? props.appRoot : {};
  const localUser = appRoot ? appRoot.user : {};
  const browserSize = appRoot.browserSize;
  const [accountEl, setAccountEl] = React.useState(false);
  const [mobileEl, setMobileEl] = React.useState(false);
  const [openItem, setOpenItem] = React.useState(appRoot.pathname.includes('settings') ? 'account' : '');


  const handleClick = (event) => {
    setMobileEl(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAccountEl(event.currentTarget);
  };

  const handleAccountClick = (event) => {
    if (openItem === 'account') {
      setOpenItem('');
    } else {
      setOpenItem('account');
    }
  };

  const handleClose = (e) => {
    const linkList = [{name: 'Schedules', link: '/app/schedule'}, {name: 'Calendar', link: '/app/calendar'}, {name: 'Meetups', link: '/app'}, {name: 'Preferences', link: '/app/prefs/invites'}]
    if (linkList.map((item) => item.name).includes(e.target.textContent)) {
      appRoot.setViewPage(linkList.filter((item) => item.name === e.target.textContent)[0].link);
    }
    setAccountEl(false);
    setMobileEl(false);
  };

  // Reset the openItem every time the pathname changes
  useEffect( ()=> {
    if (appRoot.pathname.includes('settings')) {
      setOpenItem('account');
    } else {
      setOpenItem('');
    }
  }, [appRoot.pathname])

      
  return (
    <StyledAppBar position="sticky" sx={{zIndex: '1000', backgroundColor: 'white', boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.1)', zIndex: 1100}}>         
    {/* Consider making the top a light blue?   #edf5f6 */}
      <Container ml="0" mr="0">
        <Toolbar disableGutters={true} variant="dense" sx={{height: '64px', minHeight: '64px'}}>
          
          {/****************** ADMIN HAMBURGER AND MENU IF ON MOBILE ******************/}
          <Box display={{xs: 'block', sm: appRoot.browserSize === 'sm2' ? 'block' : 'none', md: 'none' }}>
            <IconButton color="inherit" title="Menu" aria-label="Menu" onClick={handleClick} size="large">
              <MenuIcon color="primary" alt="Menu" />
            </IconButton>
            <Drawer open={mobileEl} onClose={()=>setMobileEl(false)}>
              <Box sx={{ width: '250px' }}>
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                  <IconButton color="primary" title="Close" aria-label="Close" onClick={()=>setMobileEl(false)} size="large">
                    <Close color="action" size="small" alt="Close" />
                  </IconButton>
                </Box>
                <List sx={{marginTop: 0, paddingTop: 0}}>
                <ListItem disablePadding component={ReactRouterLink} to={localUser.id ? '/app' : '/login'} onClick={handleClose} aria-label="Meetups" title="Meetups">
                    <ListItemButton>
                      <ListItemIconCustom>
                        <Forum />
                      </ListItemIconCustom>
                      <ListItemText primary="Meetups" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding component={ReactRouterLink} to={localUser.id ? '/app/calendar' : '/login'} onClick={handleClose} aria-label="Calendar" title="Calendar">
                    <ListItemButton>
                      <ListItemIconCustom>
                        <CalendarMonth />
                      </ListItemIconCustom>
                      <ListItemText primary="Calendar" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding component={ReactRouterLink} to={localUser.id ? '/app/schedule' : '/login'} onClick={handleClose} aria-label="Schedule" title="Schedule">
                    <ListItemButton>
                      <ListItemIconCustom>
                        <AccessTime />
                      </ListItemIconCustom>
                      <ListItemText primary="Schedule" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding component={ReactRouterLink} to={localUser.id ? '/app/prefs/invites' : '/login'} onClick={handleClose} aria-label="Preferences" title="Preferences">
                  <ListItemButton>
                      <ListItemIconCustom>
                        <Settings />
                      </ListItemIconCustom>
                      <ListItemText primary="Preferences" />
                    </ListItemButton>
                  </ListItem>
                </List>
                <Box mt={2} mb={2}>
                  <Divider />
                </Box>
                <List>
                <ListItem disablePadding component={ReactRouterLink} onClick={handleAccountClick} aria-label="Account" title="Account">
                  <ListItemButton>
                    <ListItemIconCustom>
                      <Person />
                    </ListItemIconCustom>
                    <ListItemText primary="Account" />
                    {openItem === 'account' ? <ExpandLess color="action" /> : <ExpandMore color="action" />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={openItem === 'account'} timeout="auto" unmountOnExit>
                  <ListItemCustom disablePadding component={ReactRouterLink} to={localUser.id ? '/app/settings/profile' : '/login'} onClick={handleClose}>
                      <ListItemButton><ListItemTextCustom primary="Profile" /></ListItemButton>
                    </ListItemCustom>
                    <ListItemCustom disablePadding component={ReactRouterLink} to={localUser.id ? '/app/settings/password' : '/login'} onClick={handleClose}>
                      <ListItemButton><ListItemTextCustom primary="Password" /></ListItemButton>
                    </ListItemCustom>
                    <ListItemCustom disablePadding component={ReactRouterLink} to={localUser.id ? '/app/settings/devices' : '/login'} onClick={handleClose}>
                      <ListItemButton><ListItemTextCustom primary="Devices" /></ListItemButton>
                    </ListItemCustom>
                    <ListItemCustom disablePadding component={ReactRouterLink} to={localUser.id ? '/app/settings/billing' : '/login'} onClick={handleClose}>
                      <ListItemButton><ListItemTextCustom primary="Billing" /></ListItemButton>
                    </ListItemCustom>
                </Collapse>
                </List>
                
                <Box sx={{position: 'absolute', bottom: '0', width: '250px'}} pb={8}>
                <List color="primary">
                  <ListItem disablePadding component={ReactRouterLink} to={'/login/goodbye'} onClick={handleClick}>
                    <ListItemButton>
                      <ListItemIconCustom>
                        <Logout />
                      </ListItemIconCustom>
                      <ListItemText primary="Logout" />
                    </ListItemButton>
                  </ListItem>
                </List>
                </Box>
              </Box>
            </Drawer>
          </Box>

          {/********* MEETIFY LOGO ******************/}
          <Box sx={{paddingTop: '13px', flexGrow: !['xs','sm2'].includes(browserSize) ? 0 : 1, display: 'flex', justifyContent: !['xs','sm2'].includes(browserSize) ? 'flex-start' : 'center'}}><Link component={ReactRouterLink} to="/" color="primary.contrastText"><img src={logo} border="0" height="40" alt="Meetify" /></Link></Box>


          {/********* DESKTOP LINKS ******************/}
            <Box sx={{display:{ xs: 'none', sm: browserSize === 'sm2' ? 'none' : 'block', md: 'block'}, flexGrow: 1}} ml={2} >
              <Stack direction="row" spacing={3} alignItems="center" justifyContent="flex-end">
                {appRoot.pathname.substring(0,4).includes('/app') || appRoot.pathname.substring(0,7).includes('/invite') ? 
                  <Button aria-controls="simple-menu" variant="text" color="primary" disableElevation onClick={()=>{appRoot.setViewPage('/app')}}>Meetups</Button>
                :
                <Button aria-controls="simple-menu" variant="text" color="primary" disableElevation component={ReactRouterLink} to="/app">Meetups</Button>
                }
                <Button aria-controls="simple-menu" aria-haspopup="true" variant="text" color="primary" classes={{endIcon: classes.label}} disableElevation endIcon={<ArrowDropDown m={0} />} onClick={handleClick2} id="navAccount">
                  {appRoot.user.firstName}
                </Button>
                {appRoot.pathname.substring(0,4).includes('/app') ? 
                  <Button variant="contained" href="/invite/what" color="secondary"><strong>Invite</strong></Button>
                :
                <Button variant="contained" href="/invite/what" color="secondary"><strong>Invite</strong></Button>
                }
              </Stack>
              <Menu
                id="account-menu"
                disableScrollLock={true}
                anchorEl={accountEl}
                keepMounted
                open={Boolean(accountEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                {appRoot.pathname.substring(0,4).includes('/app') ? 
                  <MenuItem><Link id="accountLink" onClick={()=>{setAccountEl(false); appRoot.setViewPage('/app/settings/profile')}}>Account</Link></MenuItem>
                    : 
                  <MenuItem><Link id="accountLink" href="/app/settings/profile" onClick={()=>setAccountEl(false)}>Account</Link></MenuItem>
                  }       
                  {appRoot.user.settings && appRoot.user.settings.admin && appRoot.user.settings.admin.isAdmin ?
                  <MenuItem><Link id="adminLink" component={ReactRouterLink} to={`/admin${appRoot.user.settings && appRoot.user.settings.admin && appRoot.user.settings.admin.support ? '/support/search' : '/stats/planners'}`} onClick={()=>setAccountEl(false)}><strong>ADMIN</strong></Link></MenuItem>
                  : null}
                  <MenuItem><Link id="logoutLink" component={ReactRouterLink} to="/login/goodbye" onClick={()=>setAccountEl(false)}>Logout</Link></MenuItem>
              </Menu>
            </Box>

            {/********* MOBILE ICONS ******************/}
            <Box sx={{display: {xs: 'block', sm: browserSize === 'sm2' ? 'block' : 'none', md: 'none'}}}>
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                  <Tooltip title="Invite" arrow>
                    <IconButton color="primary" aria-label="invite" size="large" href="/invite/what">
                      <AddCircleOutline />
                    </IconButton>
                  </Tooltip>
                </Stack>
            </Box>


          </Toolbar>
          </Container>
          {appRoot.user && appRoot.user.isAdmin ? <Box sx={{height: '30px', backgroundColor: 'secondary.main', fontWeight: 'bold', display:'flex', alignItems: 'center', justifyContent: 'center', color: 'headers.main'}} typography="body1">IMPORTANT: You are logged in as {appRoot.user.firstName} {appRoot.user.lastName}</Box>: null}

          </StyledAppBar>
  );
}

export default Header;

