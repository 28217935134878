import React from 'react';
import {BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { HelmetProvider } from "react-helmet-async";
// page elements
import AppRoutes from './App-routes';
import ErrorBoundary from './components/library/errorBoundary';

let theme = createTheme({
  typography: {
    body1: {
      color: '#5B5B5B',
    },
    body2: {
      color: '#5B5B5B',
    },
    h1: {
      color: '#132e53',
    },
    h2: {
      color: '#132e53',
    },
    h3: {
      color: '#132e53',
    },
    h4: {
      color: '#132e53',
    },
    h5: {
      color: '#007377',
    },
    h6: {
      color: '#007377',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#007377'
    },
    warning: {
      main: '#B86C0F'
    },
    error: {
      main: '#E00444'
    },
    success: {
      main: '#5DC98B',
    },
    secondary: {
      main: '#a8d5ba',
    },
    headers: {
      main: '#132e53',
    },
    text: {
      main: '#5B5B5B',
      primary: '#5B5B5B',
      secondary: 'rgba(19,46,83,0.54)',
    },
    divider: '#c6e6d3',
    oldDiverColor: '#a8d5ba'
  },
  components: {
    // Name of the component
    MuiToolbar: {
      styleOverrides: {
          dense: {
              height: 64,
              minHeight: 64
          }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(168, 212, 185, 0.8)',
        },
        invisible: {
          backgroundColor: 'rgb(0, 0, 0, 0)',
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 11px 15px -7px rgb(20 46 83 / 30%), 0px 24px 38px 3px rgb(20 46 83 / 24%), 0px 9px 46px 8px rgb(20 46 83 / 22%)',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          paddingLeft: '12px',
          paddingRight: '12px',
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);

// The app router, navigation, and user state
function App() {
  return (
    <ErrorBoundary>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;
