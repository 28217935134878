import React, {useEffect, useState, useRef, useCallback} from 'react';
import { useNavigate, Link as ReactRouterLink } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { Container, Box, Typography, Button, Stack, Grid, Fade, List, ListItem, ListItemIcon, Link } from '@mui/material';
import { CheckCircle, EventTwoTone, LocationOnTwoTone, LocalCafeTwoTone}  from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel'
import MetaTags from '../library/metaTags';
import LoadingIcon from '../library/loadingIcon';
import PublicFooter from '../nav/footerPublic';
import { customFade } from '../../services/guiService';
// cms
import { client } from './prismic'
import { PrismicProvider, PrismicToolbar } from '@prismicio/react'
import { useSinglePrismicDocument } from '@prismicio/react';
import { linkResolver } from './slices/slices';
// images
import diag from '../../images/pricing/blue-abstract.jpg';
import coffeeCups from '../../images/home/coffee-cups2b.jpg';
import testimonials from '../../images/home/testimonial-bground2.jpg';
import coffeeMeetup from '../../images/home/coffee-meetup.png'
import screenshot1 from '../../images/home/screenshot1.png';
import screenshot2 from '../../images/home/screenshot2.png';
import screenshot3 from '../../images/home/screenshot3.png';

const StyledList = styled(List)({
  '& .MuiListItem-root': {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 20,
    marginTop: 0,
    marginBottom: 0
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 10,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 25,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0
  },
});

const Home = (props) => {

  const imageArray = [screenshot1, screenshot2, screenshot3];
  const altArray = ['Let invitees pick the best time and place to meet', 'Find coffee shops or restaurants between two people or in a specific area', 'Coordinate the best time for two people to meet in person or virtually'];
  const [loadingState, setLoadingState] = useState('loading');
  const [width, setWidth] = useState(0);
  const [width3, setWidth3] = useState(0);
  const [height, setHeight] = useState(0);
  const [height3, setHeight3] = useState(0);
  const [fadeTime, setFadeTime] = useState([false, false, false, false, false, false, false, false, false]);
  const [document] = useSinglePrismicDocument('home_page');
  const elementRef = useRef(null);
  const meetupRef = useRef(null);
  const navigate = useNavigate();

  // Fade and slide animations
  const fade = useCallback( (direction, redirect, includeSlide) => { customFade(navigate, fadeTime.length, setFadeTime, null, direction, redirect, null, includeSlide, props.appRoot.setFadeFooter) },[props.appRoot.setFadeFooter, navigate, fadeTime.length]);

  // determine the height and width of the responsive images
  const resizeImg = () => {
    if (elementRef.current) {
      const width = elementRef.current.getBoundingClientRect().width;
      const height = 439*width/337
      setWidth(Math.floor(width));
      setHeight(Math.floor(height))
    } ;
    if (meetupRef.current) {
      const width3 = meetupRef.current.getBoundingClientRect().width > 600 ? 600 : meetupRef.current.getBoundingClientRect().width;
      const height3 = 451*width3/600
      setWidth3(Math.floor(width3));
      setHeight3(Math.floor(height3));
    }
  }

  // Initial load
  useEffect( ()=> {
    if(loadingState !== 'complete') {
      setLoadingState('complete');
      fade('in');
    }
    resizeImg();
    return () => {
      setFadeTime([true, true, true, true, true, true, true, true, true]); 
    };
  }, [loadingState, fade])

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    window.addEventListener("resize", resizeImg);
    return () => window.removeEventListener("resize", resizeImg);
  }, []);

  // preRenderReady
  useEffect( () => {
    const goRender = async () => {
      await new Promise(resolve => setTimeout(resolve, 200));
      window.prerenderReady = true;
    }
    if (!window.prerenderReady && fadeTime[fadeTime.length-1] === true) goRender();
  },[fadeTime]);


  if (loadingState !== 'complete') {
    return (
      <LoadingIcon />
    )
  } else {
    return (
      <Box>
        <MetaTags content={document} />
        
        {/* Header graphic */}
        <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
          <Box sx={{width: '100%', height: {xs: '400px', md: '475px'}, backgroundColor: '#ddebe7',  backgroundImage: `url(${coffeeCups})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', boxShadow: `0px 3px 12px 0px #c6e7e9`}} align="center">
            <Box sx={{width: '100%', height: '100%', backgroundColor: {xs: 'rgb(255,255,255,0.6)', md: 'rgb(255,255,255,0.1)'}}} align="center" pt={2} >
              <Grid container justifyContent="center" alignItems="center" sx={{height: '100%', maxWidth: 'lg'}}>
                <Grid item xs={12} md={6} sx={{height: '100%'}}>
                  <Box sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Stack sx={{height: '100%', alignItems: 'flex-start', justifyContent: 'center'}} spacing={1} ml={{xs: 0, md: 6, lg: 10}}>
                      <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                      <Box align="center" sx={{width: '100%'}}>
                        <Typography variant="h3" component="h1" sx={{textAlign: 'center', fontWeight: 'bold', textShadow: `0px 0px 16px rgb(221,235,231,0.8)`}}>Relationship-First<br />Meeting Scheduling</Typography>
                      </Box>
                      </Fade>
                      <Box align="center" sx={{width: '100%'}}>
                        <Box sx={{maxWidth: '425px'}} align="center">
                          <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                            <Typography variant="h6" component="h3" pb={4} sx={{lineHeight: '2.0em', textShadow: `0px 0px 16px rgb(221,235,231,1.0)`, fontWeight: 'bold'}}>Find a time and place to meet without back and forth emails or impersonal booking links.</Typography>
                          </Fade>
                        </Box>
                        <Fade in={fadeTime[3]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                          <Box pb={4}><Button variant="contained" color="primary" size="large" component={ReactRouterLink} to="/invite">Invite Someone</Button></Box>
                        </Fade> 
                      </Box>            
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{height: '1px'}}></Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>

        <Box mt={10}>&nbsp;</Box>

        {/* How it Works */}
        <Box align="center" pl={{xs: 3, lg: 0}} pr={{xs: 3, lg: 0}}>
          <Grid container alignItems="flex-start" sx={{maxWidth: 'lg'}}>
            <Grid item xs={12} md={3.5} alignItems={{xs: 'center', md: 'flex-start'}}>
              <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                <Box sx={{maxWidth: 337}} ref={elementRef} pt={{xs: 0, md: 4}}>
                  {height ? 
                    <Carousel interval="6000" indicatorIconButtonProps={{style: {margin: '5px'}}}>
                      {imageArray.map( (item, i) => <img key={'image' + i} alt={altArray[i]} src={item} border="0" width={width} height={height}></img> )}
                    </Carousel>
                  : null}
                </Box>
              </Fade>
            </Grid>
            <Grid item xs={12} md={8.5} sx={{mt: {xs: 4, md: 0, lg: 1}}}>
              <Fade in={fadeTime[4]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                <Box pb={4}>
                  <Typography variant="h4" sx={{fontWeight: 'bold'}}>Save Time. Be Kind.</Typography>
                </Box>
              </Fade>
              <Fade in={fadeTime[5]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
              <Grid container alignItems="stretch"  sx={{pl: {xs: 0, md: 2}}}>
                <Grid item xs={12} sm={4} sx={{mr: {xs:0, sm: 0}, mb: {xs: 5, sm: 0}, pr: {xs: 0, sm: 2}}}>
                  <Box sx={{boxShadow: '2px 2px 8px rgba(0,0,0,0.08)', borderRadius: 5, height: '100%'}} pt={3} pl={3} pr={3}>
                    <LocationOnTwoTone color="primary" sx={{minWidth: 72, minHeight: 72}} />
                    <Typography mt={1} variant="h5" component="h3" sx={{fontWeight: 'bold'}} color="text.main">Location<br />Focussed</Typography>
                    <Box sx={{textAlign: 'left'}}><Typography mt={3} variant="body1">Provide a range of choices, from face-to-face meetups at coffee shops, restaurants, or offices to virtual meetings.</Typography></Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} sx={{mr: {xs:0, sm: 0}, mb: {xs: 5, sm: 0}, pr: {xs: 0, sm: 1}, pl:{xs: 0, sm: 1}}}>
                  <Box sx={{boxShadow: '2px 2px 8px rgba(0,0,0,0.08)', borderRadius: 5, height: '100%'}}  pt={3} pl={3} pr={3}>
                    <LocalCafeTwoTone color="primary"  sx={{minWidth: 72, minHeight: 72}} />
                    <Typography mt={1} variant="h5" component="h3" sx={{fontWeight: 'bold'}} color="text.main">Personal<br />Feel</Typography>
                    <Box sx={{textAlign: 'left'}}><Typography mt={3} variant="body1">Say goodbye to mass booking links. Personalize an invite with preferred times and the ability for invitees to suggest alternatives.</Typography></Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} sx={{pl: {xs: 0, sm: 2}}}>
                  <Box sx={{boxShadow: '2px 2px 8px rgba(0,0,0,0.08)', borderRadius: 5, height: '100%'}} pt={3} pl={3} pr={3}>
                  <EventTwoTone color="primary" sx={{minWidth: 72, minHeight: 72}} />
                    <Typography mt={1} variant="h5" component="h3" sx={{fontWeight: 'bold'}} color="text.main">Simple & <br />Quick</Typography>
                    <Box sx={{textAlign: 'left'}}><Typography mt={3} variant="body1">Make it easy to respond, be reminded, or reschedule. Spend less time on software and more time engaging with people.</Typography></Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{textAlign: 'center'}} mt={8}>
                  <Link component={ReactRouterLink} to="/features">Learn about our features</Link>
                </Grid>
              </Grid>
              </Fade>
            </Grid>
          </Grid>
        </Box>

        <Box mt={12}>&nbsp;</Box>

        <Fade in={fadeTime[5]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
        <Box sx={{width: '100%', backgroundColor: '#112945', boxShadow: `0px 3px 12px 0px #c6e7e9`}}>
            <Box pt={4} pb={4} sx={{width: '100%', height: '100%', backgroundImage: `url(${diag})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
              <Stack sx={{height: '100%', textAlign: 'center'}} pr={3} pl={3} pt={8} pb={8} alignItems="center" justifyContent="center">
                <Typography variant="h6" component="h2" sx={{color: 'white', fontWeight: 'bold', lineHeight: '1.5em'}}>Does your scheduling software offer in-person options?</Typography>
                <br />
                <Box sx={{maxWidth: '920px'}} align="center"><Typography variant="h4" color="secondary" sx={{ fontWeight: 'bold', lineHeight: '1.5em'}}>8 out of 10 executives prefer face-to-face meetings. 85% say it builds stronger, more meaningful business relationships.</Typography> </Box>
                <Typography variant="body2" color="secondary" mt={4} sx={{fontWeight: 'bold'}}><Link href="https://images.forbes.com/forbesinsights/StudyPDFs/Business_Meetings_FaceToFace.pdf" target="_blank" color="secondary">Forbes Insights</Link></Typography>
              </Stack>
            </Box>
        </Box>         
      </Fade>   

      <Box mt={16}>&nbsp;</Box>

      {/* Testimonial Quotes */}
      <Fade in={fadeTime[6]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
        <Container>
          <Box sx={{width: '100%', height: '350px', boxShadow: `0px 3px 12px 0px #c6e7e9`, borderRadius: '15px', backgroundImage: {xs: `url(${testimonials})`, sm: `url(${testimonials})`}, backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <Box pt={10} pb={6} pl={3} pr={3}>
              <Box pt={2} pb={2} pl={{xs: 1, sm: 2}} pr={{xs: 1, sm: 2}} sx={{width: {xs: '95%', sm: '50%'}, backgroundColor: `white`, borderRadius: '8px', textAlign: 'center'}}>
                <Carousel interval="8000" indicatorIconButtonProps={{style: {margin: '5px'}}}>
                  <Box pt={3} pb={3} pl={{xs: 0, sm: 3}} pr={{xs: 0, sm: 3}}>
                    <Typography variant="h6" component="h5" color="primary">“Love this…. Simplify my life! That is my mantra!”</Typography>
                    <Typography variant="body1" mt={2} sx={{fontWeight: 'bold'}}>Amy Henderson</Typography>
                  </Box>
                  <Box pt={3} pb={3} pl={{xs: 0, sm: 3}} pr={{xs: 0, sm: 3}}>
                    <Typography variant="h6" component="h5" color="primary">“I just used Meetify today to coordinate grabbing beers next week. Worked like a charm!”</Typography>
                    <Typography variant="body1" mt={2} sx={{fontWeight: 'bold'}}>Kevin Carney</Typography>
                  </Box>
                  <Box pt={3} pb={3} pl={{xs: 0, sm: 3}} pr={{xs: 0, sm: 3}}>
                    <Typography variant="h6" component="h5" color="primary">“As someone who meets in person all over the city, this is a definite pain point…”</Typography>
                    <Typography variant="body1" mt={2} sx={{fontWeight: 'bold'}}>Juan Garzón</Typography>
                  </Box>
                  <Box pt={3} pb={3} pl={{xs: 0, sm: 3}} pr={{xs: 0, sm: 3}}>
                    <Typography variant="h6" component="h5" color="primary">“It was easy to use and automated an otherwise crappy back-and-forth process.”</Typography>
                    <Typography variant="body1" mt={2} sx={{fontWeight: 'bold'}}>Matt DeWald</Typography>
                  </Box>
                  <Box pt={3} pb={3} pl={{xs: 0, sm: 3}} pr={{xs: 0, sm: 3}}>
                    <Typography variant="h6" component="h5" color="primary">“I'm going to use this at least once a week!”</Typography>
                    <Typography variant="body1" mt={2} sx={{fontWeight: 'bold'}}>Patrick McElhaney</Typography>
                  </Box>
                </Carousel>
              </Box>
            </Box>
          </Box>
        </Container>
        </Fade>
        <Fade in={fadeTime[7]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
        <Box mt={4} p={2} typography="h6" component="h5" align="center" sx={{color: 'headers.main'}}>See why everyone is talking about Meetify.&nbsp;&nbsp;&nbsp;<Button component={ReactRouterLink} to="/invite" variant="contained" color="secondary" size="large">Schedule Now</Button></Box>
        </Fade>

        <Box mt={6}>&nbsp;</Box>

        <Fade in={fadeTime[7]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
        <Box>
        <Box sx={{boxShadow: '0px 50px 75px 0px rgb(168,212,186,0.3)', height: '50px'}}>&nbsp;</Box>
        <Box pt={12} pb={2}>
          <Container>
          <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={6} pr={{xs: 0, md: 8}}>
              <Box align="center" ref={meetupRef}>
                <img alt="Schedule meetups with ease" src={coffeeMeetup} width={width3} height={height3} border="0" style={{maxWidth: '600px'}} />
              </Box>
              </Grid>
              <Grid item xs={12} md={6} pr={{xs:0, sm: 5}} pl={{xs: 0, sm:5}} pt={{xs: 6, md: 0}} sx={{textAlign: {xs: 'center', md: 'left'}}}>
                <Typography variant="h4" component="h3" color="headers.main" sx={{fontWeight: 'bold'}}>Ideal to Schedule Meetings for...</Typography>
                <Typography variant="body1" mt={2} mb={2} sx={{fontWeight: 'bold'}}>anyone who wants to book virtual or in person meetups without the hassle of back-and-forth emails and texts.</Typography>
                <Grid container spacing={{xs:2, md: 8}} pl={2}>
                  <Grid item xs={5}>
                  <Box typography="body2">
                    <StyledList><ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Friends</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Salespeople</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Fundraisers</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Mentors</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Nonprofit Leaders</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Tutors</ListItem>
                    <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Board Members</ListItem>
                </StyledList>
                </Box>
                </Grid>
                <Grid item xs={7}>
                  <Box typography="body2"><StyledList>
                  <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Community Builders</ListItem>
                  <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Personal Coaches</ListItem>
                  <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Faith Community Leaders</ListItem>
                  <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Volunteers</ListItem>
                  <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Small Business Entrepreneurs</ListItem>
                  <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Recruiters</ListItem>
                  <ListItem><ListItemIcon><CheckCircle color="secondary" /></ListItemIcon>Development Directors</ListItem></StyledList></Box>
                  </Grid>
                </Grid>
                <Box mt={3}><Button component={ReactRouterLink} to="/about" variant="contained" color="primary" size="large">Invite Someone</Button></Box>
              </Grid>
              </Grid>
          </Container>
        </Box>
        </Box>
        </Fade>

        <Box mt={10}>&nbsp;</Box>

          
      {/* About Us Promo */}
      <Fade in={fadeTime[8]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
        <Box sx={{width: '100%', backgroundColor: '#edf5f6', boxShadow: `inset 0px 0px 100px 0px #c6e7e9`}} align="center" pt={12} pb={12}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} pl={2} pr={2}>
            <Stack alignItems="center" spacing={1}>
                <Fade in={fadeTime[0]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Typography variant="h3" component="h3" sx={{fontWeight: 'bold', textShadow: `2px 2px 10px #f6f6f6`}}>
                    We know scheduling. You know us.
                  </Typography>
                </Fade>
                <Fade in={fadeTime[1]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Box typography="h6" component="h4" sx={{lineHeight: '2.0em', fontWeight: 'bold', maxWidth: 'md'}} align="center">
                    Meetify was founded by the innovative creators of SignUpGenius, the web's largest group scheduling platform. We're passionate about saving people time and getting them together. 
                  </Box>
                </Fade>
                <Fade in={fadeTime[2]} timeout={Number(process.env.REACT_APP_TRANS_SPEED_MED)}>
                  <Box pt={2}><Button variant="contained" color="secondary" size="large" component={ReactRouterLink} to="/about">How it Started</Button></Box>
                </Fade>
              </Stack>
            </Box>
        </Box>
        </Fade>

        <Box mt={4}>&nbsp;</Box>
  
        {/* Modal for Video */}
        {/* <Dialog open={openVideo} onClose={ () => setOpenVideo(false) } fullWidth>
          <Box>
            <DialogContent sx={{m: 0, p: '10px'}}>
              <Box ml={0} mr={0} align="center" sx={{height: {xs: '195px', sm: '325px', maxWidth: '100%', maxHeight: '100%'}}}>
              <iframe src='https://www.youtube.com/embed/CI59hApYyGw?rel=0'
                frameForder='0'
                allow='autoplay; encrypted-media'
                allowfullScreen="allowFullScreen"
                title='About Meetify'
                width="100%"
                height="100%"
              />
              </Box>
            </DialogContent>
            <DialogActions>
              <Box  mb={2} mr={2}><Button onClick={ (e) => setOpenVideo(false) } color="primary" variant="contained">Close</Button></Box>
            </DialogActions>
          </Box>
        </Dialog> */}

        <PublicFooter appRoot={props.appRoot} />
      </Box>
    );
  } 
}

const Wrapper = (props) => {
  return (
    <PrismicProvider client={client} linkResolver={linkResolver}>
      <Home {...props} />
      {process.env.REACT_APP_ENV !== 'production' ? <PrismicToolbar repositoryName="meetify" /> : null}
    </PrismicProvider>
  )
}


export default Wrapper;