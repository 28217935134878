import React, {useEffect, useState, useRef, useCallback} from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Link, Typography, Box, Grid, Stack} from '@mui/material';
import { PrismicRichText } from '@prismicio/react';
import { format } from 'date-fns-tz';
import { initialSize } from '../../../services/guiService';

const classes = {
  articleCorners: `articleLg-imageCorners`,
  imageCorners: `image-imageCorners`,
};

const StyledBox = styled(Box)({
  [`& .${classes.articleCorners}`]: {
    borderRadius: '50px 15px 50px 15px',
    boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.15)',
    paddingRight: '0px',
  },
  [`& .${classes.imageCorners}`]: {
    borderRadius: '50px 15px 0px 0px',
    paddingRight: '0px'
  }
});

const ArticlesLarge = (props) => {

  const document = props.document;
  const [loadingState, setLoadingState] = useState('loading');
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [browserSize, setBrowserSize] = useState(initialSize());
  const imageRef = useRef();

  // determine the height and width of the responsive images
  const resizeImg = useCallback( () => {
    if (imageRef.current) {
      const width = imageRef.current.getBoundingClientRect().width;
      const height = 240*width/400
      setWidth(Math.floor(width));
      setHeight(Math.floor(height))
    };
  }, [imageRef]);

  useEffect( () => {
    if (loadingState === 'loading' && document && document.length) {
      setLoadingState('complete');
    }
    if (imageRef && !width) resizeImg();
  }, [document, loadingState, imageRef, width]);

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    const handleResize = () => {
      setBrowserSize(initialSize());
      resizeImg();
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loadingState === 'complete') {
    return (
      <StyledBox sx={{width: '100%'}}>
        <Grid container sx={{width: '100%'}}>
        {document.filter(x=>x.tags.length > 0 && x.type === 'article').map( (article, i) => {
          let padl; let padr;
          if (browserSize === 'xs') {
            padl = '16px';
            padr = '16px';
          } else if (browserSize === 'sm' || browserSize === 'sm1' || browserSize === 'sm2') {
            const remainder = i % 2;
            if (remainder === 0) {
              padl = 0;
              padr = '16px';
            } else {
              padl = '16px';
              padr = 0;
            }
          } else {
            const bigpad = browserSize === 'lg' || browserSize === 'xl' ? '32px' : '24px';
            const smallpad = browserSize === 'lg' || browserSize === 'xl' ? '16px' : '12px';
            if (i !== 0) {
              const remainder = i % 3;
              if (remainder === 0) {
                padl = 0;
                padr = bigpad;
              } else if (remainder === 1) {
                padl = smallpad;
                padr = smallpad;
              } else {
                padl = bigpad;
                padr = 0;
              }
            } else {
              padl = 0;
              padr = bigpad;
            }
          }
          return (
            <Grid item xs={12} sm={6} md={4} pb={8} sx={{paddingLeft: padl, paddingRight: padr}} key={'article' + i}>
              <Box sx={{width: '100%', height: '100%'}} ref={imageRef} className={classes.articleCorners}>
              <Stack>
                  <Link component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/resources/${article.tags.length ? article.tags[0].toLowerCase() + '/' : ''}${article.uid}`} arial-label={article.data.title[0].text}>
                    <img src={article.data.image.thumbnail.url} width={width} height={height} border="0" alt={article.data.title[0].text} className={classes.imageCorners}></img> 
                  </Link>
                <Box pl={3} pr={3} pt={1} sx={{height: '100%'}}>
                  <Typography variant="h6" component="h4" color="primary" sx={{fontWeight: 'bold'}}><Link underline="none" component={ReactRouterLink} to={`${process.env.REACT_APP_WEB_URL}/resources/${article.tags.length ? article.tags[0].toLowerCase() + '/' : ''}${article.uid}`}>{article.data.title[0].text}</Link></Typography>
                </Box>
                <Box pl={3} pr={3}>
                {article.data.summary ? <Box pt={1}><PrismicRichText field={article.data.summary} components={{
                  heading1: ({ children }) => <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
                  heading2: ({ children }) => <Typography variant="h4" component="h2" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
                  heading3: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
                  heading4: ({ children }) => <Typography variant="h5" component="h3" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
                  heading5: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold'}} mt={4} mb={2}>{children}</Typography>,
                  heading6: ({ children }) => <Typography variant="h6" component="h4" sx={{fontWeight: 'bold', color:'headers.main'}} mt={4} mb={2}>{children}</Typography>,
                  paragraph: ({ children }) => <Typography variant="body2" mb={2}>{children}</Typography>,
                  preformatted: ({ node, key }) => <pre key={key}>{node.text}</pre>,
                  strong: ({ children, key }) => <strong key={key}>{children}</strong>,
                  em: ({ children, key }) => <em key={key}>{children}</em>,
                  listItem: ({ children, key }) => <li key={key}>{children}</li>,
                  list: ({ children, key }) => <Box typography="body1"><ul key={key}>{children}</ul></Box>,
                  oListItem: ({ children, key }) => <li key={key}>{children}</li>,
                  oList: ({ children, key }) => <Box typography="body1"><ol key={key}>{children}</ol></Box>,
                }}
                internalLinkComponent={(props) => <Link {...props} />} externalLinkComponent={(props) => <Link component={ReactRouterLink} to={props.href} nofollow={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} noindex={props.href.indexOf('meetify.com') !== -1 ? 'false' : 'true'} target={props.target}>{props.children}</Link>} /></Box> : null}
                </Box>
                <Box pl={3} pr={3} pb={2}>
                <Link component={ReactRouterLink} underline="none" to={`${process.env.REACT_APP_WEB_URL}/resources/${article.tags.length ? article.tags[0].toLowerCase() + '/' : ''}${article.uid}`}  arial-label={`Read more about '${article.data.title[0].text}'`} sx={{fontWeight: 'bold'}}>Read More</Link>
                </Box>
              </Stack>
              </Box>
            </Grid>
          )
        })}
        </Grid>
      </StyledBox>
    )
  } else {
    return null;
  }
}

export default ArticlesLarge;

