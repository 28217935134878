import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import TagManager from 'react-gtm-module'
 
// for now, disable tag manager. need a test tag manager, possibly
if (process.env.REACT_APP_ENV !== 'development') {
  const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM,
      dataLayer: {
        environment: process.env.REACT_APP_ENV,
        showAds: process.env.REACT_APP_ADS
      }
  }
  
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
