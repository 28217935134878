import React from 'react';
import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    [theme.breakpoints.down("sm")]: {
    width: '100%',
    maxHeight: '96%',
    margin: '10px',
    }
  },
}));

const Custom = (props) => {
 
  return (
    <CustomDialog open={props.open} onClose={props.onClose} fullWidth={props.fullWidth} disableScrollLock={true}>
       {props.children}
    </CustomDialog>
  )
}

export default Custom;