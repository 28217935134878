import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Stack, Tooltip, Drawer, Divider, Link, Toolbar, AppBar, Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button, Menu, MenuItem} from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Home, Stars, Article, MonetizationOn, AddCircleOutline, GridView, ArrowDropDown }  from '@mui/icons-material';
import logo from '../../images/nav/Meetify-logo-200.png';
import MenuIcon from '@mui/icons-material/Menu';

// Used to style icon in menu
const PREFIX = 'Header';
const classes = {
  label: `${PREFIX}-label`,
};
const StyledAppBar = styled(AppBar)({
  [`& .${classes.label}`]: {
      marginLeft: '0px'
  },
});

const Header = (props) => {

  const appRoot = props.appRoot;
  const localUser = appRoot ? appRoot.user : {};
  const [accountEl, setAccountEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(!anchorEl);
  };

  const handleClick2 = (event) => {
      setAccountEl(event.currentTarget);
    };

  const handleClose = () => {
    setAccountEl(null);
  };

  return (
    <StyledAppBar position="sticky" sx={{zIndex: '1000', backgroundColor: 'white', boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.05)'}} id="top" >
      <Container ml="0" mr="0">
      <Toolbar disableGutters={true} variant="dense" sx={{backgroundColor: 'white'}}>
        <Box display={{ xs: 'block', md: 'none' }}>
          <IconButton color="inherit" title="Menu" aria-label="Menu" onClick={handleClick} size="large">
              <MenuIcon color="primary" alt="Menu" />
          </IconButton>
          <Drawer open={anchorEl} onClose={handleClick}>
            <Box sx={{ width: '200px' }}>
            <List>
              <ListItem disablePadding component={ReactRouterLink} to="/" onClick={handleClick} aria-label="Home" title="Home">
                <ListItemButton>
                  <ListItemIcon>
                    <Home />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                  </ListItemButton>
              </ListItem>
              {/* <ListItem disablePadding component={ReactRouterLink} to="/about" onClick={handleClick}>
                <ListItemButton>
                  <ListItemIcon>
                    <Info />
                  </ListItemIcon>
                  <ListItemText primary="About" />
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding component={ReactRouterLink} to="/features" onClick={handleClick} aria-label="Features" title="Features">
               <ListItemButton>
                  <ListItemIcon>
                    <Stars />
                  </ListItemIcon>
                  <ListItemText primary="Features" />
                </ListItemButton>
              </ListItem>
              { props.noLinks ? null : 
              <ListItem disablePadding component={ReactRouterLink} to="/pricing" onClick={handleClick} aria-label="Pricing" title="Pricing">
                <ListItemButton>
                  <ListItemIcon>
                    <MonetizationOn />
                  </ListItemIcon>
                  <ListItemText primary="Pricing" />
                </ListItemButton>
              </ListItem>
              }
              <ListItem disablePadding component={ReactRouterLink} to="/resources" onClick={handleClick} aria-label="Resources" title="Resources">
               <ListItemButton>
                  <ListItemIcon>
                    <Article />
                  </ListItemIcon>
                  <ListItemText primary="Resources" />
                </ListItemButton>
              </ListItem>
            </List>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
            <List>
              { props.noLinks ? null : 
              <ListItem disablePadding component={ReactRouterLink} to={localUser.id ? '/app' : '/login'} onClick={handleClick}>
                  <ListItemButton>
                  <ListItemIcon>
                    <GridView />
                  </ListItemIcon>
                  <ListItemText primary={localUser.id ? 'Dashboard' : 'Login'} />
                </ListItemButton>
              </ListItem>
              }
              </List>
            </Box>
          </Drawer>
        </Box>
        <Box sx={{
          align: {xs: 'left', md: 'center'},
          textAlign: {xs: 'center', md: 'left'},
          flexGrow: {xs: 1, md: 0},
          marginRight: {xs: '0px', md: '100px'}
        }}>
          <Box sx={{paddingTop: '13px'}}><Link component={ReactRouterLink} to="/" color="primary.contrastText" aria-label='Meetify Logo'><img src={logo} alt="Meetify Logo" width="132" height="40" border="0" /></Link></Box>
        </Box>
        <Box display= {{xs: 'none', md: 'block'}} sx={{ flexGrow: 1 }} >
          <Stack direction="row" spacing={7}>
            {/* <Link component={ReactRouterLink} to="/about" color="inherit" sx={{textDecoration: 'none', fontWeight: 'medium'}}>About</Link> */}
            <Link component={ReactRouterLink} to="/features" color="primary" sx={{textDecoration: 'none', fontWeight: 'medium'}}>Features</Link>
            <Link component={ReactRouterLink} to="/pricing" color="primary" sx={{textDecoration: 'none', fontWeight: 'medium'}}>Pricing</Link>
            <Link component={ReactRouterLink} to="/resources" color="primary" sx={{textDecoration: 'none', fontWeight: 'medium'}}>Resources</Link>
          </Stack>
        </Box>
        <Box display={{ xs: 'none', md: 'block' }}>
          <Stack direction="row" spacing={3} alignItems="center"> 
            {!localUser.id ? 
                <Link component={ReactRouterLink} to="/login" color="primary" sx={{textDecoration: 'none', fontWeight: 'medium'}} id="navAccount">Login</Link>
              :
                <Button 
                  aria-label="account menu"
                  aria-controls="simple-menu" 
                  aria-haspopup="true" 
                  variant="text" 
                  color="primary"
                  classes={{endIcon: classes.label}}
                  disableElevation
                  endIcon={<ArrowDropDown m={0} />} 
                  onClick={handleClick2}
                  id="navAccount">
                  {localUser.firstName}
                </Button>                                  
              }
            <Box pl={5}><Button color="secondary" variant="contained" component={Link} href="/invite"><strong>Invite</strong></Button></Box>
          </Stack>
          {localUser.id ? 
          <Menu
            id="account-menu"
            disableScrollLock={true}
            anchorEl={accountEl}
            keepMounted
            open={Boolean(accountEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MenuItem><Link component={ReactRouterLink} to="/app" onClick={handleClose}>Meetups</Link></MenuItem>
            <MenuItem><Link component={ReactRouterLink} to="/app/settings/profile" onClick={handleClose}>My Account</Link></MenuItem>
            {localUser.settings && localUser.settings.admin && localUser.settings.admin.isAdmin ?
              <MenuItem><Link component={ReactRouterLink} to={`/admin${localUser.settings && localUser.settings.admin && localUser.settings.admin.support ? '/support/search' : '/stats/planners'}`} onClick={handleClose}><strong>ADMIN</strong></Link></MenuItem>
            : null}
            <MenuItem><Link component={ReactRouterLink} to="/login/goodbye" onClick={handleClose}>Logout</Link></MenuItem>
          </Menu> : null}
        </Box>
        <Box display={{ xs: 'block', md: 'none' }}>
          <Tooltip title="Invite" arrow>
            <IconButton color="inherit" aria-label="Invite" size="large" component={Link} href="/invite">
              <AddCircleOutline color="primary" alt="Invite" />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>   
      </Container>
      {localUser && localUser.isAdmin ? <Box sx={{height: '30px', backgroundColor: 'secondary.main', fontWeight: 'bold', display:'flex', alignItems: 'center', justifyContent: 'center', color: 'headers.main'}} typography="body1">IMPORTANT: You are logged in as {localUser.firstName} {localUser.lastName}</Box>: null}
    </StyledAppBar>
  );
}

export default Header;

